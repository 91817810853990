import ODataClientWrapper, { List, ListWithStatus } from "../../../shared/utils/odataClient.wrapper";
import DocumentFlow, { DocFlowFilterTabs, DocumentFlowIdGenerator, NetworkDocumentFlowDTO } from "./documentFlow";
import { DocumentStatus, NewNetworkDocumentStatusDTO } from "./documentStatus";
import {
  DocumentFlowStage,
  NetworkDocumentFlowStageDTO
} from "./documentFlowStage";
import { ListResponse } from "../../../contracts/domains/contract/contract.service";
import { Query } from "../../../shared/queryable/query";
import { isEmpty, mergeRight } from "ramda";
import { DocFlowFilterKeys, newDocumentFlowQuery } from "./documentFlowQuery";
import { AxiosWrapper } from "../../../shared/utils/axios.wrapper";
import { withSystemAlias } from "../../../shared/domains/auth/authentication.service";
import { DocFlowItem } from "../prpo/poItem";
import { User } from "src/app/shared/domains/user/user";
import { DateTime } from "luxon";
import { AxiosRequestConfig } from "axios";
import { UserInteractionType } from "../../../shared/domains/user/userInteractionType";
import { PataflagService } from "src/app/shared/services/pataflag.service";
import { Features } from "src/app/shared/domains/core/pataflag";
import { convertToString } from "../../components/itemDrilldownTable/editRequestAllLines/helper";
import _ from 'lodash';
import { AccTabItem } from "../../components/dataTable/ItemDetails/accTabItems";
import { getNbsComplianceIndicatorTitle } from "src/app/nbsCockpit/helpers/functions";


export class DocumentFlowService {
  static async fetch(
    query: Query = newDocumentFlowQuery(),
    config: { alias: string, docFlowType: DocFlowFilterTabs } | string = null
  ): Promise<ListResponse<DocumentFlow>> {
    const q: Partial<Query> = {
      resource: "DocFlowHeadSet"
    };

    let queryClone: Query = _.cloneDeep(query);

    // below code is to ensure chat and notes filter isnt set to SAP, also to filter based on chat and notes
    // we need to get full list of data then apply the filter
    if (queryClone.filters["chatFilter"]["selected"] || queryClone.filters["notesFilter"]["selected"]) {
      delete queryClone["rows"];
      delete queryClone["page"];
      queryClone.filters["chatFilter"]["selected"] = null;
      queryClone.filters["notesFilter"]["selected"] = null;
    };

    const odata = ODataClientWrapper.get().withSystemAlias(config['alias'] ? config['alias'] : config);
    const response: List<NetworkDocumentFlowDTO> = await odata.fromQ(mergeRight(q,
      config["docFlowType"] === DocFlowFilterTabs.FAVORITE || !queryClone.filters[DocFlowFilterKeys.CREATION_DATE]['selected']['buttonName'] ?
        DocumentFlowService.removeCreatedDateFilters(queryClone) :
        DocumentFlowService.formatFilters(queryClone)))
      .count()
      .run();
    let transformedOutput: List<DocumentFlow> = response.map(DocumentFlow.FromBackend);

    if (query.filters["chatFilter"]["selected"] || query.filters["notesFilter"]["selected"]) {
      let tempData: DocumentFlow[] = transformedOutput.data;

      if (query.filters["chatFilter"]["selected"]) {
        const chatFilterSelected = query.filters["chatFilter"]["selected"][0]["id"];

        if (chatFilterSelected === "newMessages") {
          tempData = tempData.filter(docItem => docItem.CountOfUnreadComments > 0);
        }
        if (chatFilterSelected === "MessageAvailable") {
          tempData = tempData.filter(docItem => docItem.TotalCountOfComments > 0 && docItem.CountOfUnreadComments === 0);
        }
        if (chatFilterSelected === "noMessages") {
          tempData = tempData.filter(docItem => docItem.TotalCountOfComments === 0);
        };
      }

      if (query.filters["notesFilter"]["selected"]) {
        const notesFilterSelected = query.filters["notesFilter"]["selected"][0]["id"];

        if (notesFilterSelected === "newMessages") {
          tempData = tempData.filter(docItem => docItem.isThereAnyNewNotes === true);
        }
        if (notesFilterSelected === "MessageAvailable") {
          tempData = tempData.filter(docItem => docItem.IsNotesPresent === true && docItem.isThereAnyNewNotes === false);
        }
        if (notesFilterSelected === "noMessages") {
          tempData = tempData.filter(docItem => docItem.IsNotesPresent === false);
        };
      }

      let tempCount: number = tempData.length;
      let top: number = query.top;
      let skip: number = 0;
      if (query.page) { skip = (query.page * query.rows) };
      if (query.rows) {
        if (query.rows !== 10) top = query.rows;
      }
      tempData = tempData.slice(skip, skip + top);
      transformedOutput = new List(tempData, tempCount);
    };
    return transformedOutput;
  }

  static async getAccountsTabData(prNo: string, poNo: string, systemAlias: string): Promise<AccTabItem[]> {
    const response = await ODataClientWrapper.get()
      .resource("AccountDetailsSet")
      .withSystemAlias(systemAlias, true)
      .filter({ field: poNo ? "PONo" : "PRNo", value: poNo ? poNo : prNo })
      .count()
      .execute();

    return response.data.d.results.map(AccTabItem.FromBackend).sort((itemA, itemB) => {
      const compareItemNo = itemA.PRNo_Item.localeCompare(itemB.PRNo_Item);
      const compareSeqNo = itemA.SequenceNo.localeCompare(itemB.SequenceNo);
      return compareItemNo || compareSeqNo;
    }
    )
  }

  static async fetchExcel(
    query: Query = newDocumentFlowQuery(),
    config: { alias: string, docFlowType: DocFlowFilterTabs } | string = null
  )
  // : Promise<ListResponse<DocumentFlow>> 
  {
    const q: Partial<Query> = {
      // resource: "DocFlowHeadSet"
      resource: "DocFlowExportSet"
    };
    const odata = ODataClientWrapper.getExport().withSystemAlias(config['alias'] ? config['alias'] : config);

    const response = await odata.fromQExcel(mergeRight(q,
      config["docFlowType"] === DocFlowFilterTabs.FAVORITE || !query.filters[DocFlowFilterKeys.CREATION_DATE]['selected']['buttonName'] ?
        DocumentFlowService.removeCreatedDateFilters(query) :
        DocumentFlowService.formatFilters(query)))
      .count()
      .run();
    return response
    // .map(DocumentFlow.FromBackend);
  }

  private static removeCreatedDateFilters(query: Query) {
    const { ['filters']: parentValue, ...noChild } = query;
    const { [DocFlowFilterKeys.CREATION_DATE]: removedValue, ...childWithout } = parentValue;
    return { ...noChild, ['filters']: childWithout };
  }

  private static formatFilters(query: Query) {
    const { ['filters']: parentValue, ...noChild } = query;
    const isPO = parentValue.CreationDate.selected['type'] === 'PO';

    parentValue.CreationDate.selected["startDate"] = DateTime.fromISO(parentValue.CreationDate.selected["startDate"]).toFormat('yyyyMMdd');
    parentValue.CreationDate.selected["endDate"] = DateTime.fromISO(parentValue.CreationDate.selected["endDate"]).toFormat('yyyyMMdd');

    const tempParentValue = { ...parentValue }; // this is to ensure we dont actually modify the filter root state

    // SAP doesnt need creationDate, it needs either POCreationDate or DateRange, hence the below code.

    if (isPO) {
      tempParentValue['POCreationDate'] = {
        ...tempParentValue[DocFlowFilterKeys.CREATION_DATE], key: "POCreationDate"
      };
    } else {
      tempParentValue['DateRange'] = {
        ...tempParentValue[DocFlowFilterKeys.CREATION_DATE], key: "DateRange"
      };
    }

    const { [DocFlowFilterKeys.CREATION_DATE]: removedValue, ...childWithout } = tempParentValue;
    return { ...noChild, ['filters']: childWithout };
  }

  static async fetchStatus(
    query: Query = newDocumentFlowQuery(),
    config: { alias: string, docFlowType: DocFlowFilterTabs } | string
  ): Promise<ListResponse<DocumentStatus>> {
    const q: Partial<Query> = {
      resource: "DocFlwOviewSet"
    };
    const odata = ODataClientWrapper.get().withSystemAlias(config['alias'] ? config['alias'] : config);

    // below code is to ensure DocFlwOviewSet call isnt made with chat and notes filter to sap,
    // chat and notes filter was agreed to be made only for DocFlowHeadSet call, which BE handles
    const newQuery = _.cloneDeep(query);
    newQuery.filters.chatFilter && (newQuery.filters.chatFilter.selected = null);
    newQuery.filters.notesFilter && (newQuery.filters.notesFilter.selected = null);

    const response: ListWithStatus<NewNetworkDocumentStatusDTO> =
      await odata.fromQstatus(mergeRight(q,
        config["docFlowType"] === "FAVORITES" || !newQuery.filters[DocFlowFilterKeys.CREATION_DATE]['selected']['buttonName'] ?
          DocumentFlowService.removeCreatedDateFilters(newQuery) :
          DocumentFlowService.formatFilters(newQuery)))
        .count()
        .toJson()
        .run();

    return response.map(DocumentStatus.FromBackend).reduce(response.data);
  }

  static async fetchStage(df: DocumentFlow): Promise<DocumentFlowStage> {
    const result: List<NetworkDocumentFlowStageDTO> = await ODataClientWrapper.get()
      .withSystemAlias(df.SystemAlias, true)
      .resource("ProgressSet")
      .run();

    return result.map(x =>
      DocumentFlowStage.FromBackend(DocumentFlowIdGenerator(df), x)
    ).data[0];
  }

  static async updateSpender(df: DocFlowItem): Promise<any> {
    const path = "/api/sapdata/PostFromBody?query=IdSet";
    const isPrPresent = !isEmpty(df.PrNo);

    const body = isPrPresent
      ? {
        results: {
          IdNo: "D2",
          DataChanged: "X",
          IdToDocFlowItem: {
            results: [
              {
                IdNo: "D2",
                PrNo: df.PrNo,
                PrItem: df.PrItem,
                Requisitioner: df.Requisitioner
              }
            ]
          }
        }
      }
      : {
        results: {
          IdNo: "D2",
          DataChanged: "X",
          IdToDocFlowItem: {
            results: [
              {
                IdNo: "D2",
                PoNo: df.PoNo,
                PoItem: df.PoItem,
                Requisitioner: df.Requisitioner
              }
            ]
          }
        }
      };
    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(df.SystemAlias, {}, true)
    });
  }

  static async sendAttachment(formData): Promise<any> {
    const options: AxiosRequestConfig = { timeout: 15000 }
    const response = await AxiosWrapper.post(`/api/sapdata/GosUploadSet`, formData, options);
    return response.data;
  }

  static async saveDocFlowArr(actionId: UserInteractionType, dfArray: any[]): Promise<any> {
    const path = "/api/sapdata/PostFromBody?action=" + actionId + "&query=IdSet";

    const body = dfArray[0].PoNo
      ? {
        results: {
          IdNo: "D2",
          DataChanged: "X",
          IdToPOItem: {
            results: dfArray.map(df => (
              {
                IdNo: "D2",
                PurchaseOrdNo: df.PoNo,
                PurchaseOrdItemNo: df.PoItem,
                Currency: df?.PoCurrency,
                Quantity: df?.PoQuantityRaw,
                UnitOfMeasure: df?.PoQuantityUom,
                // NetPrice: df?.PoNetPriceRaw,
                NetPrice: df?.PoNetPrice,
                PriceUnit: df?.PoNetPricePerUnit,
                OrderPriceUnit: df?.PoNetPriceUnit,
                DelDate: df?.PoDeliveryDate,
                Requisitioner: df.PoRequisitioner,
                MaterialNo: df?.PoMaterial,
                ShrtTxt: df?.PoItemTxt,
              }
            ))
          }
        }
      }
      : {
        results: {
          IdNo: "D2",
          DataChanged: "X",
          IdToPRItem: {
            results: dfArray.map(df => ({
              IdNo: "D2",
              PurchaseReqNo: df.PrNo,
              PurchaseReqItemNo: df.PrItem,
              Currency: df?.PrCurrency,
              Quantity: df?.PrQuantityRaw,
              NetPrice: df?.PrNetPriceRaw,
              Overlimit: df?.PrPriceRaw,
              NetPriceUnit: df?.PrNetPriceUnit,
              DelDate: df?.PrDeliveryDate,
              Requisitioner: df.PrRequisitioner,
              Material: df?.PrMaterial,
              ShrtText: df?.PrItemTxt,
              PurchGrp: df?.PurchGrp,
              Taxcode: df?.Taxcode,
              Validity_Start: df?.Validity_Start,
              Validity_End: df?.Validity_End,
              Name1: df?.Name1,
              Name2: df?.Name2,
              Houseno: df?.Houseno,
              Street: df?.Street,
              Postalcode: df?.Postalcode,
              City: df?.City,
              Region: df?.Region,
              Country: df?.Country,
              Textsupplier: df?.Textsupplier,
              GlAccount: df?.Pr_GlAccount,
              Costcenter: df?.Pr_CostCenter,
              Wbselement: df?.Pr_wbsid,
              DelInd: df?.isToBeDeleted,
              Profitcenter: df?.PR_PROFIT,
            }))
          }
        }
      };

    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(dfArray[0].SystemAlias, {}, true)
    });
  }

  static async sendEmailToCreatorArr(dfOldArr: DocFlowItem[], dfArr: any[], user: User, ReleaseInd: boolean[], formData: any): Promise<any> {
    const options: AxiosRequestConfig = { timeout: 45000 }
    const path = "/api/CompetitiveBidding/SendEmailChangesOfCreator";
    const emails = dfOldArr[0].PoOutRecipient.split(",");
    // added below condition to avoid sending mails to PO creator for Easy Req PRs in RC
    // If PR is EasyReq PR, then we are setting POCreatoremail to "" in EditPrPopup.tsx in handleSubmit function
    !!dfArr[0].POCreatoremail && emails.push(dfOldArr[0].POCreatoremail);
    const emailsRes = emails.join();

    const body =
    {
      results: {
        IdNo: "D2",
        DataChanged: "X",
        POModifier: user.email,
        POCreator: dfOldArr[0].PoCreator,
        PORecipients: dfArr[0].POCreators ? [...dfArr[0].POCreators.map(c => c.id)] : [],
        PORecipientList: emailsRes,
        IdToPRItem: {
          results: dfArr.map((df, index) => ({
            IdNo: "D2",
            hasReleaseStrategy: ReleaseInd[index],
            poLineItemNumber: df.PoItem,
            CBFileData: df.CBFileData,
            TotalPOAmount: df.TotalPOAmount,
            SupplierStatus: df.SupplierStatus,
            IsCBRequired: df.IsCBRequired,
            CCIndicator: df.CCIndicator,
            IsEasyRequest: df.IsEasyRequest,
            resendPDF: df.SendPDF,
            poComments: df.PoComments,
            sapSystemAlias: df.SystemAlias,
            PurchaseReqNo: df.PrNo,
            PurchaseOrdNo: df.PoNo,
            PurchaseReqItemNo: df.PrItem,
            CurrencyOld: dfOldArr[index].PrCurrency,
            Currency: df.PrCurrency,
            QuantityOld: dfOldArr[index].PrQuantityRaw,
            Quantity: df.PrQuantityRaw,
            UnitOfMeasureOld: dfOldArr[index].PrQuantityUom,
            UnitOfMeasure: df.PrQuantityUom,
            NetPriceOld: df.PoDocType === "FO" ? dfOldArr[index].PrPriceRaw : dfOldArr[index].PrNetPriceRaw,
            NetPrice: df.PoDocType === "FO" ? df.PrPriceRaw : df.PrNetPriceRaw,
            NetPriceUnitOld: dfOldArr[index].PrNetPriceUnit,
            NetPriceUnit: df.PrNetPriceUnit,
            DelDateOld: dfOldArr[index].PrDeliveryDate && DateTime.fromFormat(dfOldArr[index].PrDeliveryDate, "yyyyMMdd").toFormat("dd/MM/yyyy"),
            DelDate: df.PrDeliveryDate && DateTime.fromFormat(df.PrDeliveryDate, "yyyyMMdd").toFormat("dd/MM/yyyy"),
            RequisitionerOld: dfOldArr[index].PrRequisitioner,
            Requisitioner: df.PrRequisitioner,
            MaterialOld: dfOldArr[index].PrMaterial,
            Material: df.PrMaterial,
            ShrtTextOld: dfOldArr[index].PrItemTxt,
            ShrtText: df.PrItemTxt,
            CompCode: df?.CompCode,
            CompCodeTxt: df?.CompCodeTxt,
            PoCreationDate: df?.PoCreationDate,
            PurchaseGrp: df?.PurchGrp,
            PurchaseGrpOld: dfOldArr[index].PurchGrp,
            TaxCode: df?.Taxcode,
            TaxCodeOld: dfOldArr[index]?.Taxcode,
            ValidityStartDate: df?.Validity_Start,
            ValidityStartDateOld: dfOldArr[index]?.Validity_Start,
            ValidityEndDate: df?.Validity_End,
            ValidityEndDateOld: dfOldArr[index]?.Validity_End,
            Name: df?.Name1,
            NameOld: dfOldArr[index]?.Name1,
            Name2: df?.Name2,
            Name2Old: dfOldArr[index]?.Name2,
            HouseNo: df?.Houseno,
            HouseNoOld: dfOldArr[index]?.Houseno,
            Street: df?.Street,
            StreetOld: dfOldArr[index]?.Street,
            PostalCode: df?.Postalcode,
            PostalCodeOld: dfOldArr[index]?.Postalcode,
            City: df?.City,
            CityOld: dfOldArr[index]?.City,
            Region: df?.Region,
            RegionOld: dfOldArr[index]?.Region,
            Country: df?.Country,
            CountryOld: dfOldArr[index]?.Country,
            MessageToSupplier: df?.Textsupplier,
            MessageToSupplierOld: dfOldArr[index]?.Textsupplier,
            GLAccount: df?.Pr_GlAccount,
            GLAccountOld: dfOldArr[index]?.Pr_GlAccount,
            CostCenter: df?.Pr_CostCenter,
            CostCenterOld: dfOldArr[index]?.Pr_CostCenter,
            WBSElement: df?.Pr_wbsid,
            WBSElementOld: dfOldArr[index]?.Pr_wbsid,
            ProfitCenter: df?.PR_PROFIT,
            ProfitCenterOld: dfOldArr[index]?.PR_PROFIT,
            Deleted: df?.isToBeDeleted,
            DeletedOld: df?.isToBeDeleted ? " " : df?.isToBeDeleted,
          }))
        }
      }
    };

    formData.append("dto", JSON.stringify(body));

    const result = await AxiosWrapper.post(path, formData, options);
    return result.data;
  }

  static async saveDocFlow(actionId: UserInteractionType, df: any): Promise<any> {
    const path = "/api/sapdata/PostFromBody?action=" + actionId + "&query=IdSet";

    const body = df.PoNo
      ? {
        results: {
          IdNo: "D2",
          DataChanged: "X",
          IdToPOItem: {
            results: [
              {
                IdNo: "D2",
                PurchaseOrdNo: df.PoNo,
                PurchaseOrdItemNo: df.PoItem,
                Currency: df?.PoCurrency,
                Quantity: df?.PoQuantityRaw,
                UnitOfMeasure: df?.PoQuantityUom,
                // NetPrice: df?.PoNetPriceRaw,
                NetPrice: df?.PoNetPrice,
                PriceUnit: df?.PoNetPricePerUnit,
                OrderPriceUnit: df?.PoNetPriceUnit,
                DelDate: df?.PoDeliveryDate,
                Requisitioner: df.PoRequisitioner,
                MaterialNo: df?.PoMaterial,
                ShrtTxt: df?.PoItemTxt,
              }
            ]
          }
        }
      }
      : {
        results: {
          IdNo: "D2",
          DataChanged: "X",
          IdToPRItem: {
            results: [
              {
                IdNo: "D2",
                PurchaseReqNo: df.PrNo,
                PurchaseReqItemNo: df.PrItem,
                Currency: df?.PrCurrency,
                Quantity: df?.PrQuantityRaw,
                NetPrice: df?.PrNetPriceRaw,
                Overlimit: df?.PrPriceRaw,
                NetPriceUnit: df?.PrNetPriceUnit,
                DelDate: df?.PrDeliveryDate,
                Requisitioner: df.PrRequisitioner,
                Material: df?.PrMaterial,
                ShrtText: df?.PrItemTxt,
                PurchGrp: df?.PurchGrp,
                Taxcode: df?.Taxcode,
                Validity_Start: df?.Validity_Start,
                Validity_End: df?.Validity_End,
                Name1: df?.Name1,
                Name2: df?.Name2,
                Houseno: df?.Houseno,
                Street: df?.Street,
                Postalcode: df?.Postalcode,
                City: df?.City,
                Region: df?.Region,
                Country: df?.Country,
                Textsupplier: df?.Textsupplier,
                GlAccount: df?.Pr_GlAccount,
                Costcenter: df?.Pr_CostCenter,
                Wbselement: df?.Pr_wbsid,
                DelInd: df?.isToBeDeleted,
                Profitcenter: df?.PR_PROFIT,
              }
            ]
          }
        }
      };

    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(df.SystemAlias, {}, true)
    });
  }

  static async newSaveDocFlow(actionId: UserInteractionType, docFlowItems: any): Promise<any> {
    const path = "/api/sapdata/PostFromBody?action=" + actionId + "&query=IdSet";

    const buildBody = (docFlowItems: any) => {
      const bodyResult = [];
      docFlowItems.forEach(df => {
        bodyResult.push(
          {
            IdNo: df.IdNo || "D2",
            PurchaseReqNo: df?.PrNo,
            PurchaseReqItemNo: df?.PrItem,
            PRNo_Item: df?.CopiedItemNo,
            Currency: df?.PrCurrency,
            Plant: df?.Plant,
            PR_AccCategory: df?.PR_AccCategory,
            Quantity: convertToString(df?.PrQuantityRaw),
            NetPrice: convertToString(df?.PrNetPriceRaw),
            Overlimit: convertToString(df?.PrPriceRaw),
            NetPriceUnit: convertToString(df?.PrNetPriceUnit),
            DelDate: df?.PrDeliveryDate,
            Requisitioner: df?.PrRequisitioner,
            Material: df?.PrMaterial,
            ShrtText: df?.PrItemTxt,
            PurchGrp: df?.PurchGrp,
            Taxcode: df?.Taxcode,
            Validity_Start: df?.Validity_Start,
            Validity_End: df?.Validity_End,
            Name1: df?.Name1,
            Name2: df?.Name2,
            Houseno: df?.Houseno,
            Street: df?.Street,
            Postalcode: df?.Postalcode,
            City: df?.City,
            Region: df?.Region,
            Country: df?.Country,
            Textsupplier: df?.Textsupplier,
            GlAccount: df?.Pr_GlAccount,
            Costcenter: df?.Pr_CostCenter,
            Wbselement: df?.Pr_wbsid,

            Seqno_Acc: df?.Seqno_Acc,
            Quantity_Acc: df?.Quantity_Acc,
            Dist_Percent: df?.Dist_Percent,
            Net_Value: df?.Net_Value,
            Dist_Indicator: df?.Dist_Indicator,
            Del_indicator: df?.Del_indicator,

            DelInd: df?.isToBeDeleted,
            Profitcenter: df?.PR_PROFIT,
            ExtReqNo: df?.ExtReqNo,
            PR_PaymentTerms: df?.PR_PaymentTerms,
            Title_resend: df?.Title_resend,
            // PrRequisitioner: df?.PrRequisitioner,
            Vendor: df?.Supplier,
            PR_ItemCategory: df?.PR_ItemCategory,
            PurchOrg: df?.PuchOrg,
            Requester_Resend: df?.Requester_Resend,
            Spender_Resend: df?.Spender_Resend,
            Supplier_Resend: df?.Supplier_Resend,
            Other_Resend: df?.Other_Resend,
            Status: df?.status,
            Hide_Price: df?.Hide_Price,
            Psl_ID: convertToString(df?.pslId),

            Incoterms_Text: df?.Incoterms_Text,
            VendorMaterial: df?.VendorMaterial,
            HdrSupplierComm: df?.HdrSupplierComm,
            PR_Inv_Partner: df?.PR_Inv_Partner,
            PR_Ordering_Add: df?.PR_Ordering_Add

          }
        )
      });
      return bodyResult;
    };
    const body = {
      results: {
        IdNo: docFlowItems[0]?.newId ? "D5" : "D2",
        DataChanged: "X",
        IdToPRItem: {
          results: buildBody(docFlowItems)
        }
      }
    };

    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(docFlowItems[0].SystemAlias, {}, true)
    });
  }

  static async sendEmailToCreator(dfOld: DocFlowItem, df: any, user: User, ReleaseInd: boolean, formData: any): Promise<any> {
    const options: AxiosRequestConfig = { timeout: 45000 }
    const path = "/api/CompetitiveBidding/SendEmailChangesOfCreator";
    const emails = dfOld.PoOutRecipient.split(",");
    // added below condition to avoid sending mails to PO creator for Easy Req PRs in RC
    // If PR is EasyReq PR, then we are setting POCreatoremail to "" in EditPrPopup.tsx in handleSubmit function
    !!df.POCreatoremail && emails.push(dfOld.POCreatoremail);
    const emailsRes = emails.join();

    const body =
    {
      results: {
        IdNo: "D2",
        DataChanged: "X",
        POModifier: user.email,
        POCreator: dfOld.PoCreator,
        PORecipients: df.POCreators ? [...df.POCreators.map(c => c.id)] : [],
        PORecipientList: emailsRes,
        IdToPRItem: {
          results: [
            {
              IdNo: "D2",
              hasReleaseStrategy: ReleaseInd,
              poLineItemNumber: df.PoItem,
              CBFileData: df.CBFileData,
              TotalPOAmount: df.TotalPOAmount,
              SupplierStatus: df.SupplierStatus,
              IsCBRequired: df.IsCBRequired,
              CCIndicator: df.CCIndicator,
              IsEasyRequest: df.IsEasyRequest,
              resendPDF: df.SendPDF,
              poComments: df.PoComments,
              sapSystemAlias: df.SystemAlias,
              PurchaseReqNo: df.PrNo,
              PurchaseOrdNo: df.PoNo,
              PurchaseReqItemNo: df.PrItem,
              CurrencyOld: dfOld.PrCurrency,
              Currency: df.PrCurrency,
              QuantityOld: dfOld.PrQuantityRaw,
              Quantity: df.PrQuantityRaw,
              UnitOfMeasureOld: dfOld.PrQuantityUom,
              UnitOfMeasure: df.PrQuantityUom,
              NetPriceOld: df.PoDocType === "FO" ? dfOld.PrPriceRaw : dfOld.PrNetPriceRaw,
              NetPrice: df.PoDocType === "FO" ? df.PrPriceRaw : df.PrNetPriceRaw,
              NetPriceUnitOld: dfOld.PrNetPriceUnit,
              NetPriceUnit: df.PrNetPriceUnit,
              DelDateOld: dfOld.PrDeliveryDate && DateTime.fromFormat(dfOld.PrDeliveryDate, "yyyyMMdd").toFormat("dd/MM/yyyy"),
              DelDate: df.PrDeliveryDate && DateTime.fromFormat(df.PrDeliveryDate, "yyyyMMdd").toFormat("dd/MM/yyyy"),
              RequisitionerOld: dfOld.PrRequisitioner,
              Requisitioner: df.PrRequisitioner,
              MaterialOld: dfOld.PrMaterial,
              Material: df.PrMaterial,
              ShrtTextOld: dfOld.PrItemTxt,
              ShrtText: df.PrItemTxt,
              CompCode: df?.CompCode,
              CompCodeTxt: df?.CompCodeTxt,
              PoCreationDate: df?.PoCreationDate,
              PurchaseGrp: df?.PurchGrp,
              PurchaseGrpOld: dfOld.PurchGrp,
              TaxCode: df?.Taxcode,
              TaxCodeOld: dfOld?.Taxcode,
              ValidityStartDate: df?.Validity_Start,
              ValidityStartDateOld: dfOld?.Validity_Start,
              ValidityEndDate: df?.Validity_End,
              ValidityEndDateOld: dfOld?.Validity_End,
              Name: df?.Name1,
              NameOld: dfOld?.Name1,
              Name2: df?.Name2,
              Name2Old: dfOld?.Name2,
              HouseNo: df?.Houseno,
              HouseNoOld: dfOld?.Houseno,
              Street: df?.Street,
              StreetOld: dfOld?.Street,
              PostalCode: df?.Postalcode,
              PostalCodeOld: dfOld?.Postalcode,
              City: df?.City,
              CityOld: dfOld?.City,
              Region: df?.Region,
              RegionOld: dfOld?.Region,
              Country: df?.Country,
              CountryOld: dfOld?.Country,
              MessageToSupplier: df?.Textsupplier,
              MessageToSupplierOld: dfOld?.Textsupplier,
              GLAccount: df?.Pr_GlAccount,
              GLAccountOld: dfOld?.Pr_GlAccount,
              CostCenter: df?.Pr_CostCenter,
              CostCenterOld: dfOld?.Pr_CostCenter,
              WBSElement: df?.Pr_wbsid,
              WBSElementOld: dfOld?.Pr_wbsid,
              ProfitCenter: df?.PR_PROFIT,
              ProfitCenterOld: dfOld?.PR_PROFIT,
              Deleted: df?.isToBeDeleted,
              DeletedOld: df?.isToBeDeleted ? " " : df?.isToBeDeleted,
            }
          ]
        }
      }
    };

    formData.append("dto", JSON.stringify(body));

    const result = await AxiosWrapper.post(path, formData, options);
    return result.data;
  }

  static async newSendEmailToCreator(oldDfItems: DocFlowItem[], df: any[], user: User, ReleaseInd: boolean, formData: any, SendEmailIndicator): Promise<any> {
    const options: AxiosRequestConfig = { timeout: 45000 }
    const path = "/api/CompetitiveBidding/SendEmailChangesOfCreator";
    const emails = oldDfItems[0]?.PoOutRecipient.split(",");
    // added below condition to avoid sending mails to PO creator for Easy Req PRs in RC
    // If PR is EasyReq PR, then we are setting POCreatoremail to "" in EditPrPopup.tsx in handleSubmit function
    !!df[0]?.POCreatoremail && emails.push(oldDfItems[0]?.POCreatoremail);
    const emailsRes = emails.join();

    const buildBody = (oldDocFlowItems: DocFlowItem[], newDocFlowItems: any[]) => {
      const bodyResult = [];
      newDocFlowItems.forEach((df) => {
        const [dfOld] = oldDocFlowItems.filter(item => item.PrItem === df.PrItem);
        bodyResult.push(
          {
            IdNo: "D2",
            hasReleaseStrategy: ReleaseInd,
            poLineItemNumber: df?.PoItem,
            CBFileData: df?.CBFileData,
            TotalPOAmount: df?.TotalPOAmount,
            SupplierStatus: df?.SupplierStatus,
            IsCBRequired: df?.IsCBRequired,
            CCIndicator: df?.CCIndicator,
            IsEasyRequest: df?.IsEasyRequest,
            resendPDF: df?.SendPDF,
            poComments: df?.PoComments,
            sapSystemAlias: df?.SystemAlias,
            PurchaseReqNo: df?.PrNo,
            PurchaseOrdNo: df?.PoNo,
            PurchaseReqItemNo: df?.PrItem,
            CurrencyOld: dfOld?.PrCurrency,
            Currency: df?.PrCurrency,
            QuantityOld: dfOld?.PrQuantityRaw,
            Quantity: convertToString(df?.PrQuantityRaw),
            UnitOfMeasureOld: dfOld?.PrQuantityUom,
            UnitOfMeasure: df?.PrQuantityUom,
            NetPriceOld: df?.PoDocType === "FO" ? dfOld?.PrPriceRaw : dfOld?.PrNetPriceRaw,
            NetPrice: df?.PoDocType === "FO" ? convertToString(df?.PrPriceRaw) : convertToString(df?.PrNetPriceRaw),
            NetPriceUnitOld: dfOld?.PrNetPriceUnit,
            NetPriceUnit: convertToString(df?.PrNetPriceUnit),
            DelDateOld: dfOld?.PrDeliveryDate && DateTime.fromFormat(dfOld?.PrDeliveryDate, "yyyyMMdd").toFormat("dd/MM/yyyy"),
            DelDate: df?.PrDeliveryDate && DateTime.fromFormat(df?.PrDeliveryDate, "yyyyMMdd").toFormat("dd/MM/yyyy"),
            RequisitionerOld: dfOld?.PrRequisitioner,
            Requisitioner: df?.PrRequisitioner,
            MaterialOld: dfOld?.PrMaterial,
            Material: df?.PrMaterial,
            ShrtTextOld: dfOld?.PrItemTxt,
            ShrtText: df?.PrItemTxt,
            CompCode: df?.CompCode,
            CompCodeTxt: df?.CompCodeTxt,
            PoCreationDate: df?.PoCreationDate,
            PurchaseGrp: df?.PurchGrp,
            PurchaseGrpOld: dfOld?.PurchGrp,
            TaxCode: df?.Taxcode,
            TaxCodeOld: dfOld?.Taxcode,
            ValidityStartDate: df?.Validity_Start,
            ValidityStartDateOld: dfOld?.Validity_Start,
            ValidityEndDate: df?.Validity_End,
            ValidityEndDateOld: dfOld?.Validity_End,
            Name: df?.Name1,
            NameOld: dfOld?.Name1,
            Name2: df?.Name2,
            Name2Old: dfOld?.Name2,
            HouseNo: df?.Houseno,
            HouseNoOld: dfOld?.Houseno,
            Street: df?.Street,
            StreetOld: dfOld?.Street,
            PostalCode: df?.Postalcode,
            PostalCodeOld: dfOld?.Postalcode,
            City: df?.City,
            CityOld: dfOld?.City,
            Region: df?.Region,
            RegionOld: dfOld?.Region,
            Country: df?.Country,
            CountryOld: dfOld?.Country,
            MessageToSupplier: df?.Textsupplier,
            MessageToSupplierOld: dfOld?.Textsupplier,
            GLAccount: df?.Pr_GlAccount,
            GLAccountOld: dfOld?.Pr_GlAccount,
            CostCenter: df?.Pr_CostCenter,
            CostCenterOld: dfOld?.Pr_CostCenter,
            WBSElement: df?.Pr_wbsid,
            WBSElementOld: dfOld?.Pr_wbsid,
            ProfitCenter: df?.PR_PROFIT,
            ProfitCenterOld: dfOld?.PR_PROFIT,
            Deleted: df?.isToBeDeleted,
            DeletedOld: df?.isToBeDeleted ? " " : df?.isToBeDeleted,
            PaymentTerms: df?.PR_PaymentTerms,
            PaymentTermsOld: dfOld?.PR_PaymentTerms,
          }
        )
      });
      return bodyResult;
    };

    const body =
    {
      results: {
        IdNo: "D2",
        DataChanged: "X",
        POModifier: user.email,
        POCreator: oldDfItems[0]?.PoCreator,
        SendEmail: SendEmailIndicator,
        PORecipients: df[0]?.POCreators ? [...df[0]?.POCreators.map(c => c.id)] : [],
        PORecipientList: emailsRes,
        IdToPRItem: {
          results: buildBody(oldDfItems, df)
        }
      }
    };

    formData.append("dto", JSON.stringify(body));

    const result = await AxiosWrapper.post(path, formData, options);
    return result.data;
  }

  static async sendEditDetailsToSapScope(prData: DocumentFlow,oldDfItems: DocFlowItem[], df: any[], user: User, ReleaseInd: boolean, formData: any, SendEmailIndicator): Promise<any> {
    const options: AxiosRequestConfig = { timeout: 45000 }
    const path = "/api/CompetitiveBidding/UpdateDetailsSAPScope";
    const emails = oldDfItems[0]?.PoOutRecipient.split(",");
    // added below condition to avoid sending mails to PO creator for Easy Req PRs in RC
    // If PR is EasyReq PR, then we are setting POCreatoremail to "" in EditPrPopup.tsx in handleSubmit function
    !!df[0]?.POCreatoremail && emails.push(oldDfItems[0]?.POCreatoremail);
    const emailsRes = emails.join();

    const buildBody = (oldDocFlowItems: DocFlowItem[], newDocFlowItems: any[]) => {
      const bodyResult = [];
      const complianceIndicator = getNbsComplianceIndicatorTitle(prData);
      newDocFlowItems.forEach((df) => {
        const [dfOld] = oldDocFlowItems.filter(item => item.PrItem === df.PrItem);
        bodyResult.push(
          {
            IdNo: "D2",
            hasReleaseStrategy: ReleaseInd,
            poLineItemNumber: df?.PoItem,
            CBFileData: df?.CBFileData,
            TotalPOAmount: df?.TotalPOAmount,
            SupplierStatus: df?.SupplierStatus,
            IsCBRequired: df?.IsCBRequired,
            CCIndicator: df?.CCIndicator,
            IsEasyRequest: df?.IsEasyRequest,
            resendPDF: df?.SendPDF,
            poComments: df?.PoComments,
            sapSystemAlias: df?.SystemAlias,
            PurchaseReqNo: df?.PrNo,
            PurchaseOrdNo: df?.PoNo,
            PurchaseReqItemNo: df?.PrItem,
            CurrencyOld: dfOld?.PrCurrency,
            Currency: df?.PrCurrency,
            QuantityOld: dfOld?.PrQuantityRaw,
            Quantity: convertToString(df?.PrQuantityRaw),
            UnitOfMeasureOld: dfOld?.PrQuantityUom,
            UnitOfMeasure: df?.PrQuantityUom,
            NetPriceOld: df?.PoDocType === "FO" ? dfOld?.PrPriceRaw : dfOld?.PrNetPriceRaw,
            NetPrice: df?.PoDocType === "FO" ? convertToString(df?.PrPriceRaw) : convertToString(df?.PrNetPriceRaw),
            NetPriceUnitOld: dfOld?.PrNetPriceUnit,
            NetPriceUnit: convertToString(df?.PrNetPriceUnit),
            DelDateOld: dfOld?.PrDeliveryDate && DateTime.fromFormat(dfOld?.PrDeliveryDate, "yyyyMMdd").toFormat("dd/MM/yyyy"),
            DelDate: df?.PrDeliveryDate && DateTime.fromFormat(df?.PrDeliveryDate, "yyyyMMdd").toFormat("dd/MM/yyyy"),
            RequisitionerOld: dfOld?.PrRequisitioner,
            Requisitioner: df?.PrRequisitioner,
            MaterialOld: dfOld?.PrMaterial,
            Material: df?.PrMaterial,
            ShrtTextOld: dfOld?.PrItemTxt,
            ShrtText: df?.PrItemTxt,
            CompCode: df?.CompCode,
            CompCodeTxt: df?.CompCodeTxt,
            PoCreationDate: df?.PoCreationDate,
            PurchaseGrp: df?.PurchGrp,
            PurchaseGrpOld: dfOld?.PurchGrp,
            TaxCode: df?.Taxcode,
            TaxCodeOld: dfOld?.Taxcode,
            ValidityStartDate: df?.Validity_Start,
            ValidityStartDateOld: dfOld?.Validity_Start,
            ValidityEndDate: df?.Validity_End,
            ValidityEndDateOld: dfOld?.Validity_End,
            Name: df?.Name1,
            NameOld: dfOld?.Name1,
            Name2: df?.Name2,
            Name2Old: dfOld?.Name2,
            HouseNo: df?.Houseno,
            HouseNoOld: dfOld?.Houseno,
            Street: df?.Street,
            StreetOld: dfOld?.Street,
            PostalCode: df?.Postalcode,
            PostalCodeOld: dfOld?.Postalcode,
            City: df?.City,
            CityOld: dfOld?.City,
            Region: df?.Region,
            RegionOld: dfOld?.Region,
            Country: df?.Country,
            CountryOld: dfOld?.Country,
            MessageToSupplier: df?.Textsupplier,
            MessageToSupplierOld: dfOld?.Textsupplier,
            GLAccount: df?.Pr_GlAccount,
            GLAccountOld: dfOld?.Pr_GlAccount,
            CostCenter: df?.Pr_CostCenter,
            CostCenterOld: dfOld?.Pr_CostCenter,
            WBSElement: df?.Pr_wbsid,
            WBSElementOld: dfOld?.Pr_wbsid,
            ProfitCenter: df?.PR_PROFIT,
            ProfitCenterOld: dfOld?.PR_PROFIT,
            Deleted: df?.isToBeDeleted,
            DeletedOld: df?.isToBeDeleted ? " " : df?.isToBeDeleted,
            PaymentTerms: df?.PR_PaymentTerms,
            PaymentTermsOld: dfOld?.PR_PaymentTerms,

            PrPriceRaw: convertToString(df?.PrPriceRaw),
            PrCurrency: df?.PrCurrency,
    
            Threshold_c: df?.Threshold_currency,
            Threshold_p: df?.Threshold_value,
            ThresholdStatus: Number(df?.PrPriceRaw) > Number(df?.Threshold_value) ? "Exceeded" : "Not Exceeded",

            AribaPR: prData?.AribaPR,
            AribaPO: prData?.AribaPO,
            OB_Indicator: prData?.OB_Indicator,
            OB_Rej_Ind: prData?.OB_Rej_Ind,
            CompetitiveBiddingCreatorUserId:prData?.CompetitiveBiddingCreatorUserId,
            CompetitiveBiddingIdInAzure: prData?.CompetitiveBiddingIdInAzure,
            CollectiveNumber: prData?.CollectiveNumber,
            IsCbActive: prData?.IsCbActive,
            SapAttchScn: prData?.SapAttchScn,
            ComplianceIndicator: complianceIndicator,

            Creator: prData?.Creator,
            Creatorfnam: prData?.Creatorfnam,
            Creatorlnam: prData?.Creatorlnam,
    
            PRCompanyCode: prData?.PRCompanyCode ? prData?.PRCompanyCode : "",
            POCompanyCode: prData?.POCompanyCode,
            SystemAlias: prData?.SystemAlias

          }
        )
      });
      return bodyResult;
    };
    
    const body =
    {
      results: {
        IdNo: "D2",
        DataChanged: "X",
        POModifier: user.email,
        POCreator: oldDfItems[0]?.PoCreator,
        SendEmail: false,
        PORecipients: df[0]?.POCreators ? [...df[0]?.POCreators.map(c => c.id)] : [],
        PORecipientList: emailsRes,
        IdToPRItem: {
          results: buildBody(oldDfItems, df)
        }
      }
    };

    formData.append("dto", JSON.stringify(body));

    const result = await AxiosWrapper.post(path, formData, options);
    return result.data;
  }

  static async sendEmailPO(approved: boolean, POmodifier: string, approverName: string, poNumber: string, poItemNumber: string, comment: string): Promise<any> {
    const path = "/api/CompetitiveBidding/SendEmailPOApprovedState";
    const body =
    {
      approved,
      POmodifier,
      approverName,
      poNumber,
      comment,
      ItemNoPR: poItemNumber
    };
    const result = await AxiosWrapper.post(path, body);
    return result.data;
  }

  static async fetchApprovalStatusDocFlow(easyReqPrNo) {
    const path = `/api/CompetitiveBidding/GetERPOApprovedStatus?poNumber=${easyReqPrNo}`
    const result = await AxiosWrapper.get(path);
    return result.data;
  }

  static async checkPO(poNumber: string, ItemNo: string): Promise<any> {
    const itemNoList = ItemNo?.split(",");
    const itemNumber = `&ItemNoPR=${itemNoList[0]}` || "";
    const path = `/api/CompetitiveBidding/GetPOApprovedState?poNumber=${poNumber}` + itemNumber;
    const result = await AxiosWrapper.get(path);
    return result.data;
  }

  static async savePO(
    PurchaseOrdNo: string,
    PurchaseOrdItemNo: string,
    PrItem: string,
    resendPDF: boolean,
    SystemAlias: string,
    compIndicatorSelected: string,
  ): Promise<any> {
    const path = "/api/sapdata/PostFromBody?query=IdSet";
    const itemNoList = PurchaseOrdItemNo?.split(",");

    const buildBody = () => {
      const bodyResult = [];
      itemNoList.forEach(itemNo => {
        bodyResult.push(
          {
            IdNo: "D3",
            PurchaseOrdNo: PurchaseOrdNo,
            PurchaseOrdItemNo: itemNo,
            PrItem: itemNo,
            RepeatOutput: resendPDF ? "X" : '',
            CollectiveNumber: compIndicatorSelected
          }
        )
      });
      return bodyResult;
    };

    const body = {
      results: {
        IdNo: "D3",
        DataChanged: "",
        IdToPOItem: {
          results: buildBody()
        }
      }
    };

    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(SystemAlias, {}, true)
    });
  }

  static async closePO(df: DocFlowItem): Promise<any> {
    const path = "/api/sapdata/PostFromBody?query=IdSet";
    const sendFinalInv = df.ClosePoInd === "2";
    const sendDci = df.ClosePoInd === "3";

    const body = sendFinalInv ?
      {
        results: {
          IdNo: "D2",
          DataChanged: "X",
          IdToPOItem: {
            results: [
              {
                IdNo: "D2",
                PurchaseOrdNo: df.PoNo,
                PurchaseOrdItemNo: df.PoItem,
                FinalInv: "X"
              }
            ]
          }
        }
      }
      : sendDci ?
        {
          results: {
            IdNo: "D2",
            DataChanged: "X",
            IdToPOItem: {
              results: [
                {
                  IdNo: "D2",
                  PurchaseOrdNo: df.PoNo,
                  PurchaseOrdItemNo: df.PoItem,
                  Dci: "X",
                }
              ]
            }
          }
        } : null;
    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(df.SystemAlias, {}, true)
    });
  }

  static async addToFavorite(
    df: DocumentFlow,
    PrItem: string,
    PoItem: string,
    setFavorite: boolean
  ): Promise<any> {
    const path = "/api/sapdata/PostFromBody?query=IdSet";

    const body = {
      results: {
        IdNo: "D1",
        DataChanged: "X",
        IdToDocFlowItem: {
          results: [
            {
              IdNo: "D1",
              PrNo: df.PurchaseReqNo,
              PoNo: df.PurchaseOrdNo,
              PrItem: PrItem,
              PoItem: PoItem,
              IsFavorite: setFavorite ? "X" : ""
            }
          ]
        }
      }
    };

    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(df.SystemAlias, {}, true)
    });
  }

  static async addToFavoriteHeaderLevel(
    df: DocumentFlow,
    setFavorite: boolean
  ): Promise<any> {
    const path = "/api/sapdata/PostFromBody?query=IdSet";

    const body = {
      results: {
        IdNo: "D1",
        DataChanged: "X",
        IdToDocFlowItem: {
          results: [
            {
              IdNo: "D1",
              PrNo: df.PurchaseReqNo,
              PoNo: df.PurchaseOrdNo,
              IsFavorite: setFavorite ? "X" : ""
            }
          ]
        }
      }
    };

    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(df.SystemAlias, {}, true)
    });
  }

  static async removeCbLink(
    cbId: string,
    df: Partial<DocumentFlow>
  ): Promise<boolean> {
    const path = `/api/CompetitiveBidding/RemoveCbAssociationWithEntity`;
    const body = {
      competitiveBiddingId: cbId,
      prId: {
        aribaValue: df?.AribaPR,
        sapValue: df?.PurchaseReqNo,
        systemAlias: df?.SystemAlias,
        InnerItems: []
      },
      poId: {
        aribaValue: df?.AribaPO,
        sapValue: df?.PurchaseOrdNo,
        systemAlias: df?.SystemAlias,
        InnerItems: []
      }
    };
    const result = await AxiosWrapper.post(path, body);
    return result.data;
  }

  static async getyBlockedInvoices(compCodes: string[]): Promise<any> {
    let path = `/api/sapdata/GetBlockedInvoices`;
    const response = await AxiosWrapper.get(path, {
      params: {
        insertChatMessages: true,
        companyCodes: compCodes
      }
    });
    return response.data;
  }

  static async logUserInteractionData(action: UserInteractionType) {
    try {
      let path = `/api/sapdata/LogUserInteractionData/` + action;
      await AxiosWrapper.post(path, {});
    } catch (error) {
      //User interaction logging errors cannot break application operation
    }
  }

  static async fetchPOThatCanEditRequests() {
    return [
      { id: "PH10", title: "Philippines" },
      { id: "GB10", title: "United Kingdom" },
      { id: "NW01", title: "Nestle Waters EMEA" },
      { id: "PU10", title: "NPPE" },
      { id: "FR10", title: "France" },
      { id: "NW01", title: "Nestle Waters EMEA" },
      { id: "PU10", title: "NPPE" },
      { id: "PL10", title: "Poland" },
      { id: "0810", title: "NPP  North America" },
      { id: "NW10", title: "Nestle Waters NA." },
      { id: "PA10", title: "America Central" },
      { id: "RBS1", title: "Nestle RBS NA" },
      { id: "US10", title: "USA" },
      { id: "CO10", title: "Colombia" }
    ];
  }

  static async fetchCCThatCanEditRequests() {
    return [
      { id: "PH11", title: "CPW Philippines, Inc." },
      { id: "PH15", title: "Nestle Philippines, Inc." },
      { id: "PH24", title: "NBS AOA" },
      { id: "PH26", title: "Wyeth Philippines, Inc." },
      { id: "PH27", title: "Froneri Philippines Inc." },
      { id: "GB11", title: "Nestlé UK Ltd." },
      { id: "GB12", title: "Nestec York Ltd." },
      { id: "GB21", title: "Cereal Partners UK" },
      { id: "GB23", title: "NESTLE PURINA PETCARE (U." },
      { id: "GB95", title: "Nestle Purina UK Comm Ops" },
      { id: "GB11", title: "Nestlé UK Ltd." },
      { id: "GB19", title: "Nestlé Waters UK Ltd" },
      { id: "GB23", title: "NESTLE PURINA PETCARE (U." },
      { id: "FR10", title: "Centres R&D Nestlé SAS" },
      { id: "FR12", title: "Nestlé France S.A.S." },
      { id: "FR13", title: "NHS France" },
      { id: "FR15", title: "Herta S.A.S." },
      { id: "FR1A", title: "NPPCOF" },
      { id: "FR20", title: "NESTLE PURINA PETCARE FR" },
      { id: "FR29", title: "Sté Immobilière Noisiel" },
      { id: "FR34", title: "Froneri Beauvais SAS" },
      { id: "FR41", title: "Lait Mont Blanc SA" },
      { id: "FR42", title: "Laboratoires GUIGOZ SAS" },
      { id: "FR58", title: "NES France" },
      { id: "FR65", title: "Cereal Partners France" },
      { id: "FR78", title: "SITPA S.A.S." },
      { id: "FR80", title: "Ste Prod.Aliment. Caudry" },
      { id: "FR81", title: "FRONERI DVT CTR SAS" },
      { id: "FR0W", title: "NW Market&Distrib" },
      { id: "FR0Y", title: "NW Supply Est" },
      { id: "FR45", title: "NW France" },
      { id: "FR46", title: "NW Nestle Waters" },
      { id: "FR47", title: "NW MT" },
      { id: "FR49", title: "NW SEMV" },
      { id: "FR54", title: "NW Agrivair" },
      { id: "FR57", title: "NW Supply Sud" },
      { id: "FR58", title: "NES France" },
      { id: "FR66", title: "NW SIV" },
      { id: "FR20", title: "NESTLE PURINA PETCARE FR" },
      { id: "PL10", title: "Nestle Polska S.A." },
      { id: "PL16", title: "CP Poland Torun-Pacific S" },
      { id: "PL33", title: "Nestlé Purina MO Sp zoo" },
      { id: "US39", title: "Nestle Purina Petcare Com" },
      { id: "US43", title: "Nestle Waters NA-Inc" },
      { id: "US0X", title: "MALHER, INC." },
      { id: "US1I", title: "Nestle RGO North America" },
      { id: "US0L", title: "Nestle Nutrition R&D Cent" },
      { id: "US10", title: "Nestle US R&D Center Inc." },
      { id: "US16", title: "Nestle USA Inc." },
      { id: "US22", title: "Nestle Purina Global Res." },
      { id: "US39", title: "Nestle Purina Petcare Com" },
      { id: "CO11", title: "Nestle de Colombia S.A" },
      { id: "CO13", title: "Comestibles la Rosa S.A" },
      { id: "CO16", title: "Cicolac Ltda." },
      { id: "CO17", title: "Nestle Purina Petcare de" },
      { id: "CO18", title: "Dairy Partners Americas M" }
    ];
  }
}
