import {IUserRole} from "./user";
import {IColumnsList} from "./columns.hook";
import {arraysConcat} from "../../utils/arraysHelper";

export enum PSLColumns {
  PSL_ID = "psl_id",
  PARENT_VENDOR = "parent_vendor",
  CHILD_VENDOR = "child_vendor",
  LOCAL_CHILD_VENDOR = "local_child_vendor",
  LOCAL_VENDOR = "local_vendor",
  SUPPLIER_CONTACT = "supplier_contact",
  SUPPLIER_STATUS = "supplier_status",
  VALID_FROM = "valid_from",
  VALID_TO = "valid_to",
  ZONE = "zone",
  MARKET = "market",
  COMPANY_CODE = "company_code",
  AGREEMENTS = "agreements",
  BUYER_NAME = "buyer_name",
  TEAM_NAME = "team_name",
  NOTE_REQUESTER = "note_requester",
  NOTE_LOCAL_PROCUREMENT = "note_local_procurement",
  TENDER_NUMBER = "tender_number",
  BUSINESS_UNIT = "business_unit",
  PRICE_LIST_LINK = "price_list_link",
  PRICE_AVAILABLE = "price_available",
  CREATED_BY_USER = "created_by_user",
  UPDATED_BY_USER = "updated_by_user",
  CREATED_ON = "created_on",
  UPDATED_ON = "updated_on",
  RELATED_TO_EASYBUY = "related_to_easybuy",
  STATUS_ATTACHMENTS = "status_attachments",
  STATUS_JUSTIFICATION = "status_justification",
  REJECTION = "rejection",
  FORM_STATUS = "form_status",
  PLANT = "plant",
  SCOPE = "scope",
  APPROVERS = "approvers",
  Supplier_Diversity = "Supplier Diversity",
  Market_Contact_Name = "Market Contact Name",
  Localizer = "Localizer",
  REFRENCE_NUMBER = "Ref ID"
}

export interface PslVendorColumnsList extends IColumnsList {
  id: PSLColumns;
}

export function GetDefaultPslColumns(userRole: IUserRole) {
  switch (userRole) {
    case IUserRole.REQUESTOR:
    case IUserRole.FACTORY_ANALYST:
      return PslAsRequestor();
    case IUserRole.AT_MARKET:
    case IUserRole.ABOVE_MARKET:
    case IUserRole.PPA_ABOVE_MARKET:
    case IUserRole.PPA_AT_MARKET:
      return PslAsAtMarket();
    case IUserRole.OPERATIONAL_BUYER:
    default:
      return PslAsApprover();
  }
}

export function GetPslColumns(userRole: IUserRole, columns: PslVendorColumnsList[]): PslVendorColumnsList[] {
  return arraysConcat(columns, GetDefaultPslColumns(userRole));
}

function PslAsRequestor() {
  return [
    {id: PSLColumns.PSL_ID, show: true, hidden: false},
    {id: PSLColumns.PARENT_VENDOR, show: true, hidden: true},
    {id: PSLColumns.LOCAL_CHILD_VENDOR, show: true, hidden: false},
    {id: PSLColumns.SUPPLIER_CONTACT, show: true, hidden: false},
    {id: PSLColumns.SUPPLIER_STATUS, show: true, hidden: false},
    {id: PSLColumns.VALID_FROM, show: false, hidden: false},
    {id: PSLColumns.VALID_TO, show: false, hidden: false},
    {id: PSLColumns.ZONE, show: false, hidden: false},
    {id: PSLColumns.MARKET, show: false, hidden: false},
    {id: PSLColumns.COMPANY_CODE, show: false, hidden: false},
    {id: PSLColumns.AGREEMENTS, show: false, hidden: false},
    {id: PSLColumns.BUYER_NAME, show: false, hidden: false},
    {id: PSLColumns.TEAM_NAME, show: false, hidden: false},
    {id: PSLColumns.NOTE_REQUESTER, show: true, hidden: false},
    {id: PSLColumns.NOTE_LOCAL_PROCUREMENT, show: false, hidden: true},
    {id: PSLColumns.TENDER_NUMBER, show: false, hidden: false},
    {id: PSLColumns.BUSINESS_UNIT, show: false, hidden: false},
    {id: PSLColumns.PRICE_LIST_LINK, show: false, hidden: false},
    {id: PSLColumns.PRICE_AVAILABLE, show: true, hidden: false},
    {id: PSLColumns.CREATED_BY_USER, show: false, hidden: false},
    {id: PSLColumns.CREATED_ON, show: false, hidden: false},
    {id: PSLColumns.UPDATED_BY_USER, show: false, hidden: false},
    {id: PSLColumns.UPDATED_ON, show: false, hidden: false},
    {id: PSLColumns.RELATED_TO_EASYBUY, show: false, hidden: false},
    {id: PSLColumns.STATUS_JUSTIFICATION, show: false, hidden: true},
    {id: PSLColumns.STATUS_ATTACHMENTS, show: false, hidden: true},
    {id: PSLColumns.REJECTION, show: false, hidden: false},
    {id: PSLColumns.FORM_STATUS, show: false, hidden: false},
    {id: PSLColumns.SCOPE, show: false, hidden: false},
    {id: PSLColumns.APPROVERS, show: false, hidden: false},
    {id: PSLColumns.Supplier_Diversity, show: false, hidden: false},
    {id: PSLColumns.Market_Contact_Name, show: false, hidden: false},
    {id: PSLColumns.Localizer, show: false, hidden: false},
    {id: PSLColumns.REFRENCE_NUMBER, show: true, hidden: false},
  ];
}

function PslAsAtMarket() {
  return [
    {id: PSLColumns.PSL_ID, show: true, hidden: false},
    {id: PSLColumns.PARENT_VENDOR, show: true, hidden: false},
    {id: PSLColumns.LOCAL_CHILD_VENDOR, show: true, hidden: false},
    {id: PSLColumns.SUPPLIER_CONTACT, show: false, hidden: false},
    {id: PSLColumns.SUPPLIER_STATUS, show: true, hidden: false},
    {id: PSLColumns.VALID_FROM, show: false, hidden: false},
    {id: PSLColumns.VALID_TO, show: false, hidden: false},
    {id: PSLColumns.ZONE, show: false, hidden: false},
    {id: PSLColumns.MARKET, show: false, hidden: false},
    {id: PSLColumns.COMPANY_CODE, show: true, hidden: false},
    {id: PSLColumns.AGREEMENTS, show: false, hidden: false},
    {id: PSLColumns.BUYER_NAME, show: true, hidden: false},
    {id: PSLColumns.TEAM_NAME, show: false, hidden: false},
    {id: PSLColumns.NOTE_REQUESTER, show: false, hidden: false},
    {id: PSLColumns.NOTE_LOCAL_PROCUREMENT, show: false, hidden: false},
    {id: PSLColumns.TENDER_NUMBER, show: false, hidden: false},
    {id: PSLColumns.BUSINESS_UNIT, show: true, hidden: false},
    {id: PSLColumns.PRICE_LIST_LINK, show: false, hidden: false},
    {id: PSLColumns.PRICE_AVAILABLE, show: true, hidden: false},
    {id: PSLColumns.CREATED_BY_USER, show: false, hidden: false},
    {id: PSLColumns.CREATED_ON, show: false, hidden: false},
    {id: PSLColumns.UPDATED_BY_USER, show: false, hidden: false},
    {id: PSLColumns.UPDATED_ON, show: false, hidden: false},
    {id: PSLColumns.RELATED_TO_EASYBUY, show: false, hidden: false},
    {id: PSLColumns.STATUS_JUSTIFICATION, show: false, hidden: false},
    {id: PSLColumns.STATUS_ATTACHMENTS, show: false, hidden: false},
    {id: PSLColumns.REJECTION, show: false, hidden: false},
    {id: PSLColumns.FORM_STATUS, show: false, hidden: false},
    {id: PSLColumns.SCOPE, show: false, hidden: false},
    {id: PSLColumns.APPROVERS, show: false, hidden: false},
    {id: PSLColumns.Supplier_Diversity, show: false, hidden: false},
    {id: PSLColumns.Market_Contact_Name, show: false, hidden: false},
    {id: PSLColumns.Localizer, show: false, hidden: false},
    {id: PSLColumns.REFRENCE_NUMBER, show: true, hidden: false},
  ];
}

function PslAsApprover() {
  return [
    {id: PSLColumns.PSL_ID, show: true, hidden: false},
    {id: PSLColumns.PARENT_VENDOR, show: true, hidden: false},
    {id: PSLColumns.CHILD_VENDOR, show: true, hidden: false},
    {id: PSLColumns.SUPPLIER_CONTACT, show: false, hidden: false},
    {id: PSLColumns.SUPPLIER_STATUS, show: true, hidden: false},
    {id: PSLColumns.VALID_FROM, show: true, hidden: false},
    {id: PSLColumns.VALID_TO, show: true, hidden: false},
    {id: PSLColumns.ZONE, show: false, hidden: false},
    {id: PSLColumns.MARKET, show: true, hidden: false},
    {id: PSLColumns.COMPANY_CODE, show: true, hidden: false},
    {id: PSLColumns.AGREEMENTS, show: true, hidden: false},
    {id: PSLColumns.BUYER_NAME, show: true, hidden: false},
    {id: PSLColumns.TEAM_NAME, show: false, hidden: false},
    {id: PSLColumns.NOTE_REQUESTER, show: false, hidden: false},
    {id: PSLColumns.NOTE_LOCAL_PROCUREMENT, show: true, hidden: false},
    {id: PSLColumns.TENDER_NUMBER, show: true, hidden: false},
    {id: PSLColumns.BUSINESS_UNIT, show: true, hidden: false},
    {id: PSLColumns.PRICE_LIST_LINK, show: true, hidden: false},
    {id: PSLColumns.PRICE_AVAILABLE, show: false, hidden: false},
    {id: PSLColumns.CREATED_BY_USER, show: false, hidden: false},
    {id: PSLColumns.CREATED_ON, show: false, hidden: false},
    {id: PSLColumns.UPDATED_BY_USER, show: false, hidden: false},
    {id: PSLColumns.UPDATED_ON, show: false, hidden: false},
    {id: PSLColumns.RELATED_TO_EASYBUY, show: false, hidden: false},
    {id: PSLColumns.STATUS_JUSTIFICATION, show: true, hidden: false},
    {id: PSLColumns.STATUS_ATTACHMENTS, show: true, hidden: false},
    {id: PSLColumns.REJECTION, show: false, hidden: false},
    {id: PSLColumns.FORM_STATUS, show: false, hidden: false},
    {id: PSLColumns.SCOPE, show: false, hidden: false},
    {id: PSLColumns.APPROVERS, show: false, hidden: false},
    {id: PSLColumns.Supplier_Diversity, show: false, hidden: false},
    {id: PSLColumns.Market_Contact_Name, show: false, hidden: false},
    {id: PSLColumns.Localizer, show: false, hidden: false},
    {id: PSLColumns.REFRENCE_NUMBER, show: true, hidden: false},
  ];
}