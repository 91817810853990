import { IProfileSettings, IUserTenderCalendarItem, User } from "../domains/user/user";
import { UserActions, UserActionTypes } from "../actions/user.actions";
import { assoc, assocPath, defaultTo, either, isEmpty, isNil, mergeRight } from "ramda";
import { error, loaded, loading } from './utils';
import { CompanyData } from "../domains/master/company";
import { Features, getPataFlagsDefaultValues, RemoteConfigurableValues } from "../domains/core/pataflag";
import { PataflagService } from "../services/pataflag.service";
import { DocFlowFilterTabs } from "../../docFlows/domains/documentFlow/documentFlow";

export interface UserState {
  featuresLoaded: boolean;
  features: { [module: string]: boolean };
  currentUser: User;
  companies: CompanyData[];
  tenderCalendarBuyers: IUserTenderCalendarItem[];
  tenderCalendarClusters: IUserTenderCalendarItem[];
  tenderCalendarZones: IUserTenderCalendarItem[];
  tenderCalendarMarkets: IUserTenderCalendarItem[];
  tenderCalendarCategories: IUserTenderCalendarItem[];
  tenderCalendarCompanyCodes: IUserTenderCalendarItem[];
  tenderCalendarBusinesses: IUserTenderCalendarItem[];
  profileDataLoaded: boolean;
  appFiltersLoading: boolean;
  appFiltersDataLoaded: boolean;
  liteDataLoaded: boolean;
  basicDataLoaded: boolean;
  preferredCompanyLoading: boolean;
  preferredCompanyLoaded: boolean;
  sendingFeedback: boolean;
  loading: boolean;
  error: any;
}

export const initialUserState: UserState = {
  featuresLoaded: false,
  features: getPataFlagsDefaultValues(),
  currentUser: null,
  companies: [],
  tenderCalendarBuyers: [],
  tenderCalendarClusters: [],
  tenderCalendarZones: [],
  tenderCalendarMarkets: [],
  tenderCalendarCategories: [],
  tenderCalendarCompanyCodes: [],
  tenderCalendarBusinesses: [],
  profileDataLoaded: false,
  appFiltersLoading: false,
  appFiltersDataLoaded: false,
  liteDataLoaded: false,
  basicDataLoaded: false,
  preferredCompanyLoading: false,
  preferredCompanyLoaded: false,
  sendingFeedback: false,
  loading: false,
  error: null
};

function getRoles(user) {
  let roles: any = PataflagService.getInstance().valueOf(RemoteConfigurableValues.USER_ROLES);
  try { roles = JSON.parse(roles); } catch (e) { }
  let { isMrpPlanner, npdBuyer, procSpec } = user;
  if (!either(isNil, isEmpty)(roles) && typeof roles === 'object') {
    isMrpPlanner = roles.isMrpPlanner;
    npdBuyer = roles.npdBuyer;
    procSpec = roles.procSpec;
  }
  return {
    isMrpPlanner: isMrpPlanner || PataflagService.getInstance().flag(Features.MRP_PLANNER_ROLE),
    npdBuyer: npdBuyer || PataflagService.getInstance().flag(Features.NPD_BUYER_ROLE),
    procSpec: procSpec
  }
}

function getSystemAlias(user, originalValue) {
  return {
    defaultSystemAlias: !isEmpty(user.defaultSystemAlias) ? user.defaultSystemAlias : defaultTo([], originalValue)
  }
}

function getUser(basicUser, { featuresLoaded, basicDataLoaded, currentUser }: Partial<UserState>, isLite = false) {
  basicUser.profileSettings = {
    ...basicUser.profileSettings,
    dateTimeFormat: defaultTo('dd/MM/yyyy', basicUser.profileSettings?.dateTimeFormat),
    timeZone: defaultTo('UTC', basicUser.profileSettings?.timeZone)
  };

  if (featuresLoaded) {
    basicUser = mergeRight(basicUser, getRoles(basicUser));
  }

  if (isLite && (basicDataLoaded)) {
    basicUser = assoc('filters', currentUser.filters, basicUser);
  }

  return mergeRight(basicUser, getSystemAlias(basicUser, currentUser?.defaultSystemAlias));
}

export function UserReducer(state: UserState = initialUserState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.FETCH_USER:
    case UserActionTypes.FETCH_LITE_USER:
      return loading(state);
    case UserActionTypes.FETCH_USER_APP_FILTERS:
      return mergeRight(state, {
        appFiltersLoading: true,
        appFiltersDataLoaded: false,
      });
    case UserActionTypes.SAVE_PREFERRED_COMPANY:
    case UserActionTypes.FETCH_PREFERRED_COMPANIES:
      return assoc('preferredCompanyLoading', true, state);
    case UserActionTypes.SEND_FEEDBACK:
      return assoc('sendingFeedback', true, state);
    case UserActionTypes.SEND_FEEDBACK_SUCCESS:
    case UserActionTypes.SEND_FEEDBACK_FAILURE:
      return assoc('sendingFeedback', false, state);
    case UserActionTypes.FETCH_LITE_USER_SUCCESS:
      return mergeRight(loaded(assoc('currentUser', getUser(action.user, state, true), state)), { liteDataLoaded: true });
    case UserActionTypes.FETCH_USER_SUCCESS:
      return mergeRight(loaded(assoc('currentUser', getUser(action.user, state), state)), { basicDataLoaded: true });
    case UserActionTypes.SAVE_CONTRACT_FILTERS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'contracts'], action.filters, state));
    case UserActionTypes.SAVE_DOCFLOW_FILTERS_SUCCESS:
      const key = action.docType === DocFlowFilterTabs.FAVORITE ? 'favDocumentFlow' : 'documentFlow';
      return loaded(assocPath(['currentUser', 'filters', key], action.filters, state));
    case UserActionTypes.SAVE_INVOICES_FILTERS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'invoices'], action.filters, state));
    case UserActionTypes.SAVE_PSL_FILTERS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'psl'], action.filters, state));
    case UserActionTypes.SAVE_CB_FILTERS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'competitiveBidding'], action.filters, state));
    case UserActionTypes.SAVE_CONTRACT_CALENDAR_FILTERS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'contractCalendar'], action.filters, state));
    case UserActionTypes.SAVE_MRP_FILTERS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'mrp'], action.filters, state));
    case UserActionTypes.SAVE_BA_FILTERS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'businessApproval'], action.filters, state));
    case UserActionTypes.SAVE_PA_FILTERS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'purchasingApproval'], action.filters, state));
      case UserActionTypes.SAVE_PSL_CATALOGS_FILTERS_SUCCESS:
        return loaded(assocPath(['currentUser', 'filters', 'pslCatalog'], action.filters, state));
    case UserActionTypes.SAVE_NBS_FILTERS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'nbsCockpit'], action.filters, state));
    case UserActionTypes.SAVE_NBS_TEMPLATE_FILTERS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'nbsTemplate'], action.filters, state));
    case UserActionTypes.SAVE_NBS_BLOCKED_PARKED_FILTERS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'blockedParked'], action.filters, state));
      case UserActionTypes.SAVE_NBS_SAP_SCOPE_FILTERS_SUCCESS:
        return loaded(assocPath(['currentUser', 'filters', 'sapScope'], action.filters, state));
        case UserActionTypes.SAVE_NBS_TRACKER_FILTERS_SUCCESS:
          return loaded(assocPath(['currentUser', 'filters', 'nbsTracker'], action.filters, state));
    case UserActionTypes.SAVE_PSL_VENDORS_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "PslVendors"], action.columns, state));
    case UserActionTypes.SAVE_PSL_MYTASKS_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "PSlMyTasks"], action.columns, state));
    case UserActionTypes.SAVE_PSL_MATERIALS_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "PslMaterials"], action.columns, state));
    case UserActionTypes.SAVE_RC_DOCS_TAB_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "RcDocsTab"], action.columns, state));
    case UserActionTypes.SAVE_RC_INVOICES_TAB_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "RcInvoicesTab"], action.columns, state));
    case UserActionTypes.SAVE_RC_DOCS_ITEMTAB_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "RCItemsColumns"], action.columns, state));
    case UserActionTypes.SAVE_RC_INVOICES_ITEMTAB_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "RCItemsColumnsInv"], action.columns, state));
    case UserActionTypes.SAVE_RC_DOCS_GRTAB_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "RCGrsColumns"], action.columns, state));
    case UserActionTypes.SAVE_RC_INVOICES_GRTAB_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "RCGrsColumnsInv"], action.columns, state));
    case UserActionTypes.SAVE_RC_DOCS_IRTAB_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "RCIrsColumns"], action.columns, state));
    case UserActionTypes.SAVE_RC_INVOICES_IRTAB_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "RCIrsColumnsInv"], action.columns, state));
    case UserActionTypes.SAVE_RC_DOCS_ACCOUNTTAB_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "RCAccColumns"], action.columns, state));

    case UserActionTypes.SAVE_NBS_EASY_REQUEST_TAB_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "NbsEasyRequestTab"], action.columns, state));

    case UserActionTypes.SAVE_NBS_BLOCKED_PARKED_TAB_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "NbsBlockedParkedTab"], action.columns, state));

    case UserActionTypes.SAVE_NBS_TRACKER_TAB_COLUMNS_SUCCESS:
      return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "NbsTrackerTab"], action.columns, state));

      case UserActionTypes.SAVE_PSL_CATALOGS_COLUMNS_SUCCESS:
        return loaded(assocPath(['currentUser', 'filters', 'frontendLists', "PslCatalogColumns"], action.columns, state));

    case UserActionTypes.FETCH_USER_APP_FILTERS_SUCCESS:
      return mergeRight(
        assocPath(['currentUser', 'filters'], mergeRight(state.currentUser.filters, action.filters), state), {
        appFiltersLoading: false,
        appFiltersDataLoaded: true
      });
    case UserActionTypes.FETCH_PREFERRED_COMPANIES_SUCCESS:
      return mergeRight(state, {
        companies: action.companies,
        preferredCompanyLoading: false,
        preferredCompanyLoaded: true
      });
    case UserActionTypes.SAVE_PREFERRED_COMPANY_SUCCESS:
      return mergeRight(state, {
        currentUser: <User>mergeRight(state.currentUser, {
          userPreferredCompanyCode: action.payload.userPreferredCompanyCode,
          userPreferredCompanyName: action.payload.userPreferredCompanyName,
          selectedZoneId: action.payload.selectedZoneId
        }),
        preferredCompanyLoading: false
      });
    case UserActionTypes.VIEW_CHANGES_SUCCESS:
      return assoc('currentUser', mergeRight(state.currentUser, {
        changes: [],
        changeIds: []
      }), state);
    case UserActionTypes.SAVE_PREFERRED_ZONE:
      return mergeRight(state, { currentUser: <User>mergeRight(state.currentUser, { selectedZoneId: action.zone }) });
    case UserActionTypes.FETCH_USER_FAILURE:
    case UserActionTypes.SAVE_CONTRACT_FILTERS_FAILURE:
    case UserActionTypes.SAVE_CONTRACT_CALENDAR_FILTERS_FAILURE:
    case UserActionTypes.SAVE_BA_FILTERS_FAILURE:
    case UserActionTypes.SAVE_PA_FILTERS_FAILURE:
      return error(action.error)(state);
    case UserActionTypes.FETCH_PREFERRED_COMPANIES_FAILURE:
    case UserActionTypes.SAVE_PREFERRED_COMPANY_FAILURE:
      return mergeRight(state, { error: action.error, preferredCompanyLoading: false });
    case UserActionTypes.FETCH_TENDER_CALENDAR_INFO_SUCCESS:
      return mergeRight(state, {
        tenderCalendarBuyers: action.buyers,
        tenderCalendarClusters: action.clusters,
        tenderCalendarZones: action.zones,
        tenderCalendarMarkets: action.markets,
        tenderCalendarCategories: action.categories,
        tenderCalendarCompanyCodes: action.companyCodes,
        tenderCalendarBusinesses: action.businesses
      });
    case UserActionTypes.FEATURES_LOADED:
      return mergeRight(state, {
        currentUser: <User>mergeRight(state.currentUser, getRoles(state.currentUser)),
        features: action.features,
        featuresLoaded: true
      });
    case UserActionTypes.FETCH_USER_PROFILE_SUCCESS:
      return mergeRight(state, {
        currentUser: <User>mergeRight(state.currentUser, {
          profileSettings: {
            dateTimeFormat: defaultTo('dd/MM/yyyy', action.profile?.dateTimeFormat),
            timeZone: defaultTo('UTC', action.profile?.timeZone),
            decimalNotation: action.profile?.decimalNotation,
            contractSpecificProfile: action.profile?.contractSpecificProfile
          } as IProfileSettings
        }),
        profileDataLoaded: true
      });
    case UserActionTypes.TENDER_CALENDAR_SYNCHRONIZED:
      return assocPath(['currentUser', 'profileSettings', 'contractSpecificProfile'], action.settings, state);
    default:
      return state;
  }
}
